/* eslint-disable */
export function go () {
  if (/Android|Windows Phone|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) || window.innerWidth < 600) {
    if (location.pathname !== '/fe/website/smallLoan/homeMobile.html') {
      location.href = location.origin + '/fe/website/smallLoan/homeMobile.html'
    }
  } else {
    if (location.pathname !== '/fe/website/smallLoan/home.html') {
      location.href = location.origin + '/fe/website/smallLoan/home.html'
    }
  }
}
export function isPhone() {
  return /Android|Windows Phone|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) || window.innerWidth < 600
}
