const footer = $(`<div class="footer-main">
  <div class="box">
    <p class="title">合规经营 行稳致远</p>
    <div class="footer-xy"></div>
    <p class="tips-1">深圳市小赢小额贷款有限责任公司</p>
    <div class="tel no-tel"><i></i>信访/投诉热线：400-101-3830（9:00-18:00）</div>
    <div class="hr"></div>
    <div class="tips-mobile-1">深圳市小赢小额贷款有限责任公司</div>
    <div class="tips-2"><i></i><p>全国网贷牌照 深金监贷[2021]58号 <span>Copyright © 2021小赢小贷®版权所有</span>  <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021109509号</a></p></div>
    <div class="tips-mobile-2">
      <i></i>
      <p>全国网贷牌照 深金监贷[2021]58号</p>
    </div>
    <p class="tips-mobile tips-mobile-3">Copyright © 2021小赢小贷®版权所有</p>
    <p class="tips-mobile tips-mobile-4"><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021109509号</a></p>
  </div>
</div>`)
export default footer
