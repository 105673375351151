const headerMobile = $(`<div class="header-main-mobile">
<div class="logo"></div>
<div class="right-nav">
  <div class="nav-top"></div>
  <div class="nav-bottom"></div>
</div>
</div>
<div class="nav-box">
<div class="nav-item nav-item-1" style="background: #404042">
<p class="tips-1">首页</p>
<p class="tips">HOME PAGE</p>
</div>
<div class="nav-item nav-item-2" style="background: #323234">
  <p class="tips-1">关于我们</p>
  <p class="nav-content-2 tips">ABOUT US</p>
  <p class="nav-content-2-1 tips">企业发展状况</p>
</div>
<div class="nav-item nav-item-3" style="background: #222224">
<p class="tips-1">新闻中心</p>
<p class="tips">NEWS CENTER</p>
</div>
<div class="nav-item nav-item-4" style="background: #000000">
<p class="tips-1">联系我们</p>
<p class="tips">CONTACT US</p>
</div>
<div class="nav-item nav-item-5" style="background: #171719">
<p class="tips-1">客服/投诉热线</p>
<p class="tips">CUSTOMER SERVICE PHONE</p>
<div class="tel-text no-tel"><i></i><p>400-101-3830（9:00-18:00）</p></div>
</div>
<div class="close">×</div>
</div>`)
$(document).ready(() => {
  let flag = true
  $('.close').click(() => {
    $('.nav-box').removeClass('nav-box-show').addClass('nav-box-hide')
    $('.nav-item-5').one('animationend', () => {
      $('.nav-box').addClass('nav-box-display-none')
    })
    $('#app').css('position', 'relative')
    $('.header-main-mobile').show()
    return false
  })
  $('.right-nav').click(() => {
    flag = true
    $('.nav-box').removeClass('nav-box-hide').removeClass('nav-box-display-none').addClass('nav-box-show')
    $('.nav-content-2-1').hide()
    $('.nav-content-2').show()
    $('.nav-item-5').one('animationend', () => {
      $('#app').css('position', 'fixed')
      $('.header-main-mobile').hide()
    })
  })
  $('.nav-item-1').click(() => {
    $('.nav-item .tips-1').removeClass('title')
    $('.nav-item-1 .tips-1').addClass('title')
    setTimeout(() => {
      location.href = location.origin + '/fe/website/smallLoan/homeMobile.html'
    }, 300)
  })
  $('.nav-item-2').click(() => {
    $('.nav-content-2').hide()
    $('.nav-content-2-1').fadeIn()
    if (flag) {
      flag = false
    } else {
      flag = true
    }
    $('.nav-item .tips-1').removeClass('title')
    $('.nav-item-2 .tips-1').addClass('title')
    setTimeout(() => {
      if (flag) {
        location.href = location.origin + '/fe/website/smallLoan/about.html'
      }
    }, 300)
  })
  $('.nav-item-3').click(() => {
    $('.nav-item .tips-1').removeClass('title')
    $('.nav-item-3 .tips-1').addClass('title')
    setTimeout(() => {
      location.href = location.origin + '/fe/website/smallLoan/news.html'
    }, 300)
  })
  $('.nav-item-4').click(() => {
    $('.nav-item .tips-1').removeClass('title')
    $('.nav-item-4 .tips-1').addClass('title')
    setTimeout(() => {
      location.href = location.origin + '/fe/website/smallLoan/contact.html'
    }, 300)
  })
  window.addEventListener('scroll', function () {
    const t = $('body, html').scrollTop()
    if (t > 50) {
      $('.header-main-mobile').addClass('header-main-active')
    } else {
      $('.header-main-mobile').removeClass('header-main-active')
    }
  })
})

export default headerMobile
