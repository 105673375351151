const header = $(`<div class="header-main">
<div class="logo"></div>
<ul>
<li class="li"><a class="a1">首页</a></li>
<li class="li-2">
<a class="a2">关于我们</a>
<div class="li-2-1"><a>企业发展状况</a></div>
</li>
<li class="li"><a class="a3">新闻中心</a></li>
<li class="li"><a class="a4">联系我们</a></li>
</ul>
<div class="header-tel no-tel"><i class="tel-icon"></i><p>客服/投诉热线：400-101-3830（09:00-18:00）</p></div>
</div>`)
$(document).ready(() => {
  let flag = false
  $('.li').hover(() => {
    $('.header-main').removeClass('header-main-hover')
    $('.li-2-1').hide()
    $('.header-main').addClass('header-main-hover-1')
  }, () => {

  })
  $('.li-2-1').hide()
  $('#app').click(() => {
    if (flag) {
      flag = false
      $('.header-main').removeClass('header-main-hover')
      $('.li-2-1').hide()
    }
  })
  $('.logo').click(() => {
    location.href = location.origin + '/fe/website/smallLoan/home.html'
  })
  // }).hover(() => {
  //   $('.logo').addClass('logo-active')
  // }, () => {
  //   $('.logo').removeClass('logo-active')
  // })
  $('.header-tel').hover(() => {
    $('.header-tel').addClass('header-tel-active')
  }, () => {
    $('.header-tel').removeClass('header-tel-active')
  })
  $('.a1').click(() => {
    location.href = location.origin + '/fe/website/smallLoan/home.html'
  })
  $('.li-2').hover(() => {
    $('.li-2-1').fadeIn()
    $('.header-main').addClass('header-main-hover')
    $('.li-2-1').click(() => {
      location.href = location.origin + '/fe/website/smallLoan/about.html'
    })
  })
  $('.header-main').hover(() => {}, () => {
    $('.li-2-1').fadeOut()
    $('.header-main').removeClass('header-main-hover')
    $('.header-main').removeClass('header-main-hover-1')
  })
  $('.a3').click(() => {
    location.href = location.origin + '/fe/website/smallLoan/news.html'
  })
  $('.a4').click(() => {
    location.href = location.origin + '/fe/website/smallLoan/contact.html'
  })
  $('.li-2').click(() => {
    flag = true
    $('.header-main').addClass('header-main-hover')
    $('.li-2-1').fadeIn()
    $('.li-2-1').click(() => {
      location.href = location.origin + '/fe/website/smallLoan/about.html'
    })
    return false
  })
  window.addEventListener('scroll', function () {
    const t = $('body, html').scrollTop()
    if (t > 0) {
      $('.header-main').addClass('header-main-active')
    } else {
      $('.header-main').removeClass('header-main-active')
    }
  })
})
export default header
